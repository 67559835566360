body, div, button, input, p, img, video, select, option, h1, h2, h3, h4, h5, h6 {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Hind', sans-serif;
}

input:focus{
  outline: none;
}

.pointer {
  cursor: pointer;
}

.navi:hover{
  box-shadow: 0 0 4px 4px rgba(80, 80, 80, 0.42);
  color: rgba(255, 255, 255, 1);
  font-weight: 800;
  background-color: rgba(80, 80, 80, 0.8);
}

.body-row:hover{
  box-shadow: 0 0 4px 4px rgba(200, 200, 200, 0.42);
  color: rgba(255, 255, 255, 1);
  font-weight: 800;
  background-color: rgba(200, 200, 200, 1);
  z-index: 100;
}

::-webkit-scrollbar {
  width: 8px;
  background: rgba(255,255,255,0);
}
::-webkit-scrollbar-thumb{
  background-color: rgba(255,255,255,0.4);
}
.edit-button:hover {
  background-color: white;
  color: red;
}
.edit-confirm-button {
  background-color: green;
  color: white;
}
.edit-cancel-button {
  background-color: white;
  color: white;
}